import styled from 'styled-components';

export const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 90px 15px 76px 14px;
    background: ${(props: any) => props.theme.colors.white};
    text-align: center;
    text-transform: uppercase;
    
    button {
        height: 50px;
        width: 161px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 98px 39px 94px;
        text-align: left;
        height: 340px;
        position: relative;

        button {
            position: absolute;
            left: 229px;
            bottom: 110px;
        }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        padding: 149px 607px 141px 150px;
        text-align: left;
        height: 440px;
        position: relative;

        button {
            position: absolute;
            left: 392px;
            bottom: 141px;
        }
    }
`;

export const StyledButtonContents = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    color: black;
`;