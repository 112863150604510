import styled from 'styled-components';

export const StyledPage = styled.div`
    background: ${(props: any) => props.theme.colors.primaryBrand1};
`;

export const StyledHeadingContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 435px;
    padding: 145px 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        text-align: left;
        padding: 178px 39px 133px;
        width: 690px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        text-align: left;
        padding: 242px 607px 158px 150px;
        width: 100%;
        height: 450px;
    }
`;