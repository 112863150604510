import Athlete from '../images/page_banners/aotg_Banner_Athlete_low.jpg';
import Parent from '../images/page_banners/aotg_Banner_Parents_low.jpg';
import Coach from '../images/page_banners/aotg_Banner_Coach_low.jpg';

export const slides = [
    {
      image: Athlete,
      link: '/whositfor/athlete',
      title: 'ATHLETES',
      text: `Successful athletes always find a way to push through when all seems lost. They're masters at tackling adversity and negotiating any challenge that stands in the way of success. Throughout the programme, you'll learn the same skills elite athletes use to stay mentally fit and resilient.`
    },
    {
      image: Parent,
      link: '/whositfor/parent',
      title: 'PARENTS',
      text: `Ahead of the Game helps parents understand, recognise and manage mental health issues in their family, including how to start a conversation when you think something is wrong. Throughout the programme, you'll gain important skills and knowledge that underpin the mental health of the people in your life.`
    },
    {
      image: Coach,
      link: '/whositfor/coach',
      title: 'COACHES',
      text: `Ahead of the Game helps coaches understand, recognise and manage mental health issues among their athletes. Throughout the programme, coaches will gain important skills and knowledge that underpin youth mental health.`
    },
  ];