import React, { Component } from 'react';

export class ContactUsContainer extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isSubmitted: false
        };
    }

    renderChildren(children: any) {
        return React.Children.map(children, (child: any) => {
            return React.cloneElement(child, {
                isSubmitted: this.state.isSubmitted,
                onNavigateBack: (isSubmitted: boolean): any => this.setState({
                    isSubmitted: isSubmitted
                })
            });
        });
    }

    render() {
        return (
            <>
                {
                    this.renderChildren(this.props.children)
                }
            </>
        );
    }
}