import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Text } from '@movember/mo-gel'


export const StyledPartnerSubtitle = styled(Text)`
  text-decoration: none;
  max-width: 75%;
  color: ${(props: any) => props.theme.colors.black};
`;

export const StyledImage = styled.img`
  vertical-align: top;
  position: relative;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 175px;
  height: 120px;
  object-fit: contain;

`;


export const StyledSubtitleContainer = styled.div`
  visibility: visible;
  display: block;
  text-align: center;
  align-items: center; 
  justify-content: center; 
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  text-decoration: none;
  width: 175px;
`;

export const StyledLogoContainer = styled.div`
  display: block;
  position: relative;
  flex-wrap: wrap;
  align-items: center; 
  justify-content: center; 
  text-decoration: none;
  padding: 20px 30px;
  margin-right: 0%;
  margin-left: 0%;
  height: 120px;
  width: 175px;
  pointer-events: auto;
  border-bottom: solid 2px ${(props: any) => props.theme.colors.primaryBrand1};
  ;

  :hover {
    opacity: 1;
  }

`;


export const StyledNavigationItem = styled(NavLink)`
  text-decoration: none;


:hover ${StyledLogoContainer}{
  opacity: 1;
  height: 120px;
  border-bottom: solid 6px ${(props: any) => props.theme.colors.primaryBrand1};
}
`;