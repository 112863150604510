import React, { Component } from 'react';

import { StyledHeroBanner, StyledHeroCallToAction, StyledHeroImage } from './HeroBanner.styled';
import { BannerImage } from '../../typings/BannerImage.type';
import { CallToAction  } from '../../typings/CallToAction.type';
import { CallToActionComponent } from '../CallToAction/CallToAction';

export interface HeroBannerProps {
  callToAction: CallToAction;
  heroBanners: BannerImage[];
}

export class HeroBanner extends Component<HeroBannerProps> {
  render() {
    const { callToAction, heroBanners } = this.props;
    return (
   
      <StyledHeroBanner>
        <StyledHeroImage heroBanners={heroBanners}/>
        <StyledHeroCallToAction>
          <CallToActionComponent callToAction={callToAction} buttonWidth="165px" headingLevel="h1"/>
        </StyledHeroCallToAction>
      </StyledHeroBanner>
    );
  }
}
