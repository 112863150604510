import styled, { css } from 'styled-components';

export const StyledDiagonalContainer = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props: any) => props.theme.colors.primaryBrand1};

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    height: auto;
  };

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    height: 713px;
    flex-direction: row;
  };
`;

export const StyledDiagonalImage = styled.div`
  width: 100%;
  height: 302px;
  background: url(${(props: any) => props.images[2]}) no-repeat top;
  background-size: cover;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    width: 100%;
    height: 640px;
    background: url(${(props: any) => props.images[1]}) no-repeat left top;
    background-size: cover;
  };
  
  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    height: 713px;
    background: url(${(props: any) => props.images[0]}) no-repeat left top;
    background-size: cover;
  };
`;

const getBeforeStyle = (width: string, height: string, skewValue: number, skewDirection: string, shadow?: string) => {
  return css`
  :before {
    content:'';
    width: ${width}; 
    height: ${height};
    position: absolute;
    top: 0; 
    left: 0;
    background: ${(props: any) => props.theme.colors.primaryBrand1};
    transform: skew${skewDirection}(-${skewValue}deg);
    transform-origin: 0 0;
    ${shadow}
  }
`;
};

export const StyledDiagonalOverlay = styled.div`
  width: 100%;
  height: 100%;
 
  ${(props: any) => {
    return css`
      ${getBeforeStyle('100%', '456px', 10, 'Y', 'box-shadow: 0px -20px 41px 0 rgba(0, 0, 0, 0.23) inset')}

      @media screen and (min-width: ${props.theme.breakpoints.sm}) {
        ${getBeforeStyle('100%', '55%', 10, 'Y', 'box-shadow: 0px -20px 41px 0 rgba(0, 0, 0, 0.23) inset')}
      };

      @media screen and (min-width: ${props.theme.breakpoints.lg}) {
        width: 50%;
        height: 713px;
        ${getBeforeStyle('895px', '100%', 15, 'X', 'box-shadow: -20px 0 41px 0 rgba(0, 0, 0, 0.23) inset')}
      };
    `;

  }}
`;

export const StyledDiagonalCallToAction = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 150px 50px 0;
  margin: auto;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    width: 80%;
  };

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    width: 70%;
    padding-top: 0px;
  };
`;