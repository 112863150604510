import React from 'react';
import { StyledFooter,
         StyledContainer,
         StyledLinks,
         StyledLink,
         StyledPartners } from './Footer.styled';
import { Text } from '@movember/mo-gel';
import ALogo from '../../images/white@3x.png';
import { ReactComponent as MoLogo } from '../../images/footer/Mo-Primary_w.svg';


export const Footer = (props: any) => {

    return (
        <StyledFooter>
            <StyledContainer>
                <StyledLinks>
                    <StyledLink to="/gethelp">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                            Get help
                        </Text>
                    </StyledLink>
                    {/* <StyledLink to="/signup">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                            Sign Up
                        </Text>
                    </StyledLink> */}
                    <StyledLink to="/contact">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                            Contact
                        </Text>
                    </StyledLink>                    
                    <StyledLink to="/terms">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                            Terms & Conditions
                        </Text>
                    </StyledLink>
                    <StyledLink to="/privacy">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                        Privacy Statement
                        </Text>
                    </StyledLink>
                </StyledLinks>
                <StyledPartners>
                    <img src={ALogo} alt="Ahead of the game" />
                    {/* <img src={MovemberLogo} alt="Movember Logo" /> */}
                    <MoLogo />
                </StyledPartners>
            </StyledContainer>
            <Text tag="p" level="xxSmall" marginTop="0" marginBottom="0" align="center">
                © 2021 - Movember  |   All right reserved
            </Text>
        </StyledFooter>
    );
};