import React, { Component } from 'react';

import DiagonalSplitImage from '../../../images/page_banners/Coaches_header_desktop.png';
import DiagonalSplitImageTablet from '../../../images/page_banners/Coaches_header_tablet.png';
import DiagonalSplitImageMobile from '../../../images/page_banners/Coaches_header_mobile.png';

import { CallToAction } from '../../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledCoachPage } from './Coach.styled';
import { Size } from '../../../typings/Size.type';
import { HalfImageHalfText } from '../../../components/HalfImageHalfText/HalfImageHalfText';
import HalfImage from '../../../images/coaches/Coach_desktop.jpg';
import { HowItWorks } from '../../../components/HowItWorks/HowItWorks';
import { CoachesHowItWorksContent } from '../../../contents/howItWorks';
import { withRouter } from 'react-router-dom';

class CoachPage extends Component<any> { 
  render() {

  const diagonalBannerCallToAction: CallToAction = {
    heading: 'COACHES',
    subHeading: `Ahead of the Game helps sport coaches develop the skills and knowledge they need to understand, recognise and support mental health and wellbeing in their athletes.
    
As part of the programme, coaches will attend one workshop. This session will help you recognise the warning signs of mental health issues among young athletes. You'll learn how to start a conversation about mental health with players or parents. At the end, you'll also have a deeper understanding of the support channels available to you.    
    `,
    callToActionLabel: 'Register',
    onClick: (): void => this.props.history.push({pathname: '/contact', search: 'coach'})
  };

  const slides = [
    {
      image: HalfImage,
      heading: 'Your Role in athlete Mental Health',
        // tslint:disable-next-line: max-line-length
      subHeading: 'Learn some real skills, like how you can take action if someone on your team is struggling with their mental health.'
    },
    {
      image: HalfImage,
      heading: 'How to tell if an athlete might be struggling',
      // tslint:disable-next-line: max-line-length
      subHeading: 'Make sure that you have your athlete\'s back by understanding when they might be struggling, and how you could help them out.'
    },
    {
      image: HalfImage,
      heading: 'Having important conversations',
      // tslint:disable-next-line: max-line-length
      subHeading: 'Talking about mental health can be awkward. Learn how to start a conversation with athletes or parents.'
    },
      
  ];

  return (
    <StyledCoachPage>
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small}/>
      <HalfImageHalfText slides={slides} />
      <HowItWorks numberOfTimelineComponents={2} content={ CoachesHowItWorksContent } />
    </StyledCoachPage>
    );
  }
}

const Coach = withRouter(CoachPage);

export { Coach as CoachPage };