import styled, { css } from 'styled-components';
import { Heading, Text } from '@movember/mo-gel';

const timelineNumberDimensions = '50px';

export const StyledTimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    margin-bottom: 40px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        flex-direction: row;
        width: 100%;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 20px;
        margin-bottom: 0px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        flex-direction: column;
        align-items: flex-end;
        width: 457px;
    }
`;

export const StyledTimelineMain = styled.div`
    margin: 0;
    display: flex;
    width: 100%;
    /* height: 244px; */
    padding-top: ${(props: any) => {
        return props.isFirst ? '22px' : 0;
    }};

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        flex-direction: column;
        justify-content: flex-start;
        width: 211px;
        /* height: 275px; */
        padding-top: 22px;
        margin: 0 14px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        flex-direction: row;
        padding-top: 0;
        width: auto;
        height: 176px;
        margin: 0;
    }
`;

export const StyledTimelineNumberContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96px;
    position: relative;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        flex-direction: row;
        justify-content: center;
        height: 62px;
        width: 100%;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 96px;
    }
`;

export const StyledTimelineNumber = styled.div`
    width: ${timelineNumberDimensions};
    height: ${timelineNumberDimensions};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
    background-color: ${(props: any) => props.theme.colors.white};
    color: black;
    font-size: 25px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        z-index: 1;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        z-index: 0;
    }

    p {
        font-family: 'Passion One';
        font-size: 25px;
    }
`;

export const StyledConnectingLine = styled.div`
    ${(props: any) => !props.isLast ? css`
        height: calc(100% - 50px);
        width: 2px;
        ` : css``
    }
    
    background: ${(props: any) => props.theme.colors.primaryBrand3};

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        position: absolute;
        width: ${(props: any) => props.isLast || props.isFirst ? '65%' : '110%'};
        height: 2px;
        order: 0;
        ${(props: any) => props.isFirst ? css`
            right: -20px;
        ` : css`` }

        ${(props: any) => props.isLast ? css`
            left: -20px;
        ` : css`` }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        position: static;
        ${(props: any) => !props.isLast ? css`
            height: calc(100% - 50px);
            width: 2px;
            ` : css`
                width: 0;
                height: 0;
            `
        }
    }
`;

export const StyledTextContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-top: 17px;
    padding-right: 30px;
    width: calc(100% - 96px);

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        width: 100%;
        align-items: center;
        text-align: center;
        padding-right: 0;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: calc(100% - 96px);
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        padding: 0;
    }
`;

export const StyledHeading = styled(Heading)`
    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        width: 166px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 304px;
        margin: 0;
    }
`;

export const StyledParagraphContainer = styled(Text)`
    width: 100%;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 304px;
    }
`;