import React, { Component } from 'react';

import { HeroBanner } from '../HeroBanner/HeroBanner'
// import { HeroVideo } from '../HeroVideo/HeroVideo';

export interface HeroState {
  width: number;
}

export class Hero extends Component<any, HeroState> {
  constructor(props: any) {
    super(props);
    this.state = {
      width: 0
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { width } = this.state;
    const { callToAction, heroBanners } = this.props;

    return(
    <>
      {width < 768 ? <HeroBanner callToAction={callToAction} heroBanners={heroBanners} /> : <HeroBanner callToAction={callToAction} heroBanners={heroBanners} />}
      {/* {width < 768 ? <HeroBanner callToAction={callToAction} heroBanners={heroBanners} /> : <HeroVideo callToAction={callToAction} videoName="/desktopHeroVideo.mp4" />} */}
    </>
    );
  }
}
