import styled from 'styled-components';
import { Heading, Button, Text } from '@movember/mo-gel';

export const StyledDocumentaryContainer = styled.div`
    position:relative;
    background: #000000;
    padding: 100px min(150px, 5vw);

    text-align: center;

    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 40px min(150px, 5vw);
    }
`;

export const StyledDocumentatryContent = styled.div`
    position: relative;
`;

export const StyledDocumentaryHeading = styled(Heading)`
    margin-bottom: 24px;
    text-align: center;
`;

export const StyledDocumentaryBlurb = styled(Text)`
    display: block;

    width: 600px;
    max-width: 100%;

    margin: auto;

    margin-bottom: 48px;
    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin-bottom: 40px;
    }
`;

export const StyledDocumentaryButton = styled(Button)`
    max-width: 314px;
    
    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
        max-width: 184px;
    }
`;

export const StyledButtonContents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    
    svg g path {
        fill: #000000;
    }

    :before {
        content: 'Watch the Documentary';
        text-transform: uppercase;
        display: inline-block;
        font-weight: 700;
        font-size: 24px;
        font-family: ${(props: any) => props.theme.typography.defaultFontFamily};
    }

    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
        :before {
            content: 'Watch It';
            display: inline-block;
        }
    }
`;

export const StyledDocumentaryVideoContainer = styled.div`
    max-width: 1080px;
    margin: auto;
    margin-top: 48px;

    @media screen and (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin-top: 24px;
    }
`;

export const StyledDocumentaryVideoContent = styled.div`
    position: relative;

    text-align: center;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    height: 0;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    }
`;

export const StyledDocumentaryVideo = styled.iframe`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`;