import React, { Component } from 'react';
import { Text } from '@movember/mo-gel';

import Logo from '../../../images/white.png';
import LogoDark from '../../../images/black-2.png';

import { 
  StyledHeader,
  StyledNavigationItem,
  StyledNavigationList,
  StyledSubNavigation,
  StyledNavigationItemText, 
  StyledSubNavItem,
  StyledImageNav
} from './DesktopHeader.styled'; 
import { Link } from '../../../typings/Links.type';

export interface DesktopHeaderProps {
  navLinks: Link[];
  whoForNavLinks: Link[];
}

export interface DesktopHeaderState {
  hover: boolean;
}

export class DesktopHeader extends Component<DesktopHeaderProps, DesktopHeaderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hover: false
    };
  }
  handleMouseEnter = () => {
    this.setState({ hover: true});
  };

  handleMouseLeave = () => {
    this.setState({ hover: false});
  };
  
  subNavigationLinks = (whoForNavLinks: Link[]) => {
    const { hover } = this.state;
    return(
     <>
     {hover && <StyledSubNavigation>
        {whoForNavLinks.map((subNav, subIndex) => 
          <StyledSubNavItem
            to={subNav.link}
            key={`sub-navigation-link-${subIndex}`}
            onClick={this.handleMouseLeave}
          >
            <Text>{subNav.title}</Text>
          </StyledSubNavItem>
        )}
      </StyledSubNavigation>}
     </>
     
   );
  }

  render() {
    const { navLinks, whoForNavLinks } = this.props;
    const { hover } = this.state;
   
    return(
    <>
      <StyledHeader hover={hover}>
        <StyledImageNav
          to="/"  
          onMouseEnter={this.handleMouseEnter} 
          onMouseLeave={this.handleMouseLeave}
        >
          <img src={hover ? LogoDark : Logo} alt="home page"/>
        </StyledImageNav>
        <StyledNavigationList>
          {navLinks.map((navLink, index) =>
          <React.Fragment key={`navigation-link-${index}`} >
              <StyledNavigationItem
                to={navLink.link}
                title={navLink.title}
                onMouseEnter={this.handleMouseEnter} 
                onMouseLeave={this.handleMouseLeave}
              >
                <StyledNavigationItemText>{navLink.title}</StyledNavigationItemText>
                {navLink.title === 'Who\'s it for?' && (
                  this.subNavigationLinks(whoForNavLinks)
                )}
              </StyledNavigationItem>
            </React.Fragment>
          )}
        </StyledNavigationList>
      </StyledHeader>
    </>);
  }
}
