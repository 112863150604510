import React, { Component } from 'react';

import { CallToAction } from '../../typings/CallToAction.type';
import { CallToActionComponent } from '../CallToAction/CallToAction';
import { 
  StyledDiagonalImage, 
  StyledDiagonalCallToAction,
  StyledDiagonalContainer } from './DiagonalSplitBanner.styled';
import { Size } from '../../typings/Size.type';

export interface DiagonalSplitBannerProps {
  callToAction: CallToAction;
  images: string[];
  size: Size;
  headingLevel?: string;
}

export class DiagonalSplitBanner extends Component<DiagonalSplitBannerProps> {
  render() {
    const { callToAction, images,  headingLevel } = this.props;

    return (
      <StyledDiagonalContainer>
          <StyledDiagonalCallToAction>
            <CallToActionComponent hoverColor="#fff" callToAction={callToAction} buttonColor="#66a6fb" buttonWidth="195px" headingLevel={headingLevel || 'h1'}/>
          </StyledDiagonalCallToAction>

        <StyledDiagonalImage images={images} />
      </StyledDiagonalContainer>
    );
  }
}
