import styled from 'styled-components';

export const StyledAboutPage = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
`;

export const StyledAboutHowItWorks = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 75px 15px 80px;
  background: ${(props: any) => props.theme.colors.primaryBrand3};
  text-align: left;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 78px 80px 86px;
  }

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    padding: 145.7px 150px 154.3px;
  }
`;

export const StyledActionContainer = styled.div`
  width: 100%;
  margin-bottom: 95px;

  p {
    color: ${(props: any) => props.theme.colors.primaryBrand1};
  }

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    margin-bottom: 0;
    width: 37%;
  }
`;

export const StyledHeading = styled.div`

`;

export const StyledSubHeading = styled.div`

`;

export const StyledButtonContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

export const StyledSubSection = styled.div`
  padding: 20px 0;
`;