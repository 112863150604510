import React from 'react';
import { StyledInfoContainer,
         StyledInfoPanel,
         StyledHeading,
         StyledLocationHeading,
         StyledLink,
         StyledText } from './InfoPanel.styled';
import { UKHelpContactInfo, IrelandHelpContactInfo } from '../../../contents/getHelp';

const InfoPanel = (dataSourse: any, title: string) => {
    return (
        <>
            <StyledLocationHeading color="black">{ title }</StyledLocationHeading>
            <StyledInfoContainer>
                    {
                        dataSourse.map((info: {heading: string, links: string[], phone: string[]}, index: number) => (
                            <StyledInfoPanel key={`help-info-panel-${index}`}>
                                <StyledHeading color="black">
                                    { info.heading }
                                </StyledHeading>
                                {
                                    info.phone.map((phone: string, i: number) => (
                                        <StyledLink
                                            to="/gethelp"
                                            key={`help-info-${i}`}
                                            onClick={() => console.log(phone)}
                                        >
                                            <StyledText>{ phone }</StyledText>
                                        </StyledLink>
                                    ))
                                }
                                 {
                                    info.links.map((link: string, i: number) => (
                                        <StyledText><a href={ "http://" + link } rel="noopener noreferrer" target="_blank">{ link }</a></StyledText>
                                    ))
                                }
                            </StyledInfoPanel>
                        ))
                    }
            </StyledInfoContainer>
        </>
    );
};

export const UkInfo =() =>{
    return InfoPanel(UKHelpContactInfo, 'United Kingdom Resources');
}

export const IrelandInfo = () =>{
    return InfoPanel(IrelandHelpContactInfo, 'Ireland Resources');
}