import styled from 'styled-components';
import { Heading, Button } from '@movember/mo-gel';
import { Link } from 'react-router-dom';

export const StyledGetHelpContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 53px 15px 99px;
    z-index: 1000;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 107px 40px auto;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        padding: 154px 150px 80px 150px;
        align-items: flex-start;
    }
`;

export const StyledHeading = styled(Heading)`
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 30px;
    line-height: 32px;
    text-transform: uppercase;
    z-index: 1000;
    margin-bottom: 42px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        width: 689px;
        font-size: 40px;
        line-height: 0.95;
        margin-bottom: 57px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 876px;
        text-align: left;
    }
`;

export const StyledButtonContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1px;
    bottom: 0;
    filter: drop-shadow(-1px -1px 3px rgba(0, 0, 0, .4));
    z-index: 1000;
`;

export const StyledGoToTopButton = styled(Button)`
    width: 51px;
    height: 39px;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: white;
    padding: 0;
    min-width: 0;
`;

export const StyledIcon = styled.div`
    display: flex;
    justify-content: flex-start;
    transform: rotate(-90deg);

    svg {
        g {
            g:nth-of-type(2) {
                path {
                    fill: black
                }
            }
        }
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    pointer-events: none;
    text-decoration-color: ${(props: any) => props.theme.colors.primaryBrand1};
    color: black;

    :hover {
        color: ${(props: any) => props.theme.colors.primaryBrand1}
    }
`;