import React from 'react';
import { withTheme } from 'styled-components';
import { Heading, Text } from '@movember/mo-gel';
import {
    StyledContainer,
    StyledHeadingContainer,
    StyledTextContainer,
    StyledButton,
    StyledButtonContents
} from './HowItWorks.styled';

import { Timeline } from '../Timeline/Timeline';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';
import { withRouter } from 'react-router-dom';

export const HowItWorks = withTheme(
    withRouter(
        (props: any) => {
            const { heading, text, timelineHeadings } = props.content;
            const { numberOfTimelineComponents } = props;
            const { content } = props.theme.colors;
    
            return (
                <StyledContainer>
                    <StyledTextContainer>
                        <StyledHeadingContainer>
                            <Heading level="h3" color={content} marginBottom="20px">{ heading }</Heading>
                        </StyledHeadingContainer>
                        <Text as="p" display="block" color={content}>
                            { text }
                        </Text>
                    </StyledTextContainer>
                    <Timeline numberOfTimelineComponents={ numberOfTimelineComponents } timelineHeadings={timelineHeadings}/>
                    <StyledButton 
                        primary={false} 
                        width="161px"
                        onClick={() => props.history.push('/contact')}>
                        <StyledButtonContents>
                            Register<RightArrow/>
                        </StyledButtonContents>
                    </StyledButton>
                </StyledContainer>
            );
        }
    )
);