import React from 'react';
import { StyledPage,
         StyledHeadingContainer } from './ContactUs.styled';
import { Heading } from '@movember/mo-gel';
import { ContactUsForm } from '../../components/ContactUs/ContactUsForm/ContactUsForm';
import { ContactUsConfirmation } from '../../components/ContactUs/ContactUsConfirmation/ContactUsConfirmation';
import { ContactUsContainer } from '../../components/ContactUs/ContactUsContainer';

export const ContactUsPage = (props: any) => {
    return (
        <StyledPage>
            <StyledHeadingContainer>
                <Heading level="h3" color="white" marginBottom="0">
                    Want to take part or know more?
                </Heading>
            </StyledHeadingContainer>
            <ContactUsContainer>
                <ContactUsForm />
                <ContactUsConfirmation />
            </ContactUsContainer>
        </StyledPage>
    );
};