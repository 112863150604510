import { CallToAction } from '../typings/CallToAction.type';

export type HomePageContent = {
   heroBanner: CallToAction;
   diagonalBanner: CallToAction;
};

export const homePageContent: HomePageContent = {
  heroBanner: {
    heading: 'talk more, play better',
    callToActionLabel: 'register'
  },
  diagonalBanner: {
    heading: 'Ahead of the Game: Talk More, Play Better',
    subHeading: `When athletes feel mentally fit they can play better. Ahead of the Game teaches young athletes how to talk about mental health, get help when needed and overcome life's challenges.

This programme is multi-layered and includes educational workshops and materials for athletes, parents and coaches.
    `,
    callToActionLabel: 'Find out more'
  }
};