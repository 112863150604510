import React, { Component } from 'react';

import DiagonalSplitImage from '../../../images/page_banners/Athletes_header_desktop.png';
import DiagonalSplitImageTablet from '../../../images/page_banners/Athletes_header_tablet.png';
import DiagonalSplitImageMobile from '../../../images/page_banners/Athletes_header_mobile.png';

import { CallToAction } from '../../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledAthletePage } from './Athlete.styled';
import { Size } from '../../../typings/Size.type';
import { HalfImageHalfText } from '../../../components/HalfImageHalfText/HalfImageHalfText';
import HalfImage1 from '../../../images/athletes/Athletes_desktop.jpg';
// import HalfImage2 from '../../../images/athletes/Athlete.jpg';
// import HalfImage3 from '../../../images/athletes/Locker.jpg';

import { HowItWorks } from '../../../components/HowItWorks/HowItWorks';
import { AthletesHowItWorksContent } from '../../../contents/howItWorks';
import { withRouter } from 'react-router-dom';

class AthletePage extends Component<any> { 
  render() {

  const diagonalBannerCallToAction: CallToAction = {
    heading: 'ATHLETES',
    subHeading: `Ahead of the Game uses sport to help young athletes gain the skills and knowledge that underpin good mental health and resilience.

As part of the programme, athletes will attend two workshops. You'll learn how to kick off conversations about mental health and recognise warning signs of mental health problems. You'll also gain the skills to help a mate out who might be going through a tough time. At the end, you'll have sharper skills to overcome challenges in sport and in life.    
    `,
    callToActionLabel: 'Register',
    onClick: (): void => this.props.history.push({pathname: '/contact', search: 'athlete'})
  };

  const slides = [
    {
      image: HalfImage1,
      heading: 'HOW TO DEAL WITH THE TOUGH STUFF',
        // tslint:disable-next-line: max-line-length
      subHeading: `Sometimes sport gets hard, and sometimes life gets harder. Learn a set of skills that will help you overcome the challenges in sport and life.`
    },
    {
      image: HalfImage1,
      heading: 'HOW TO TELL IF A TEAMMATE IS STRUGGLING',
      // tslint:disable-next-line: max-line-length
      subHeading: `Make sure you have your teammate's back by understanding when they might be struggling, and how you can help.`
    },
    {
      image: HalfImage1,
      heading: 'HAVING IMPORTANT CONVERSATIONS',
      // tslint:disable-next-line: max-line-length
      subHeading: `Talking about mental health can be awkward. Learn how to start a conversation, and when to ask for help.`
    },
      
  ];

  return (
    <StyledAthletePage>
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small}/>
      <HalfImageHalfText slides={slides} />
      <HowItWorks numberOfTimelineComponents={3} content={AthletesHowItWorksContent} />
    </StyledAthletePage>
  );
  }
}

const Athlete = withRouter(AthletePage);

export { Athlete as AthletePage };