import UniversityOfWoolongongLogo from '../images/partners/UOW.png'
import MovemberLogo from '../images/partners/Movember.png';
import RugbyLeagueWorldCup from '../images/partners/RLWC2021.png';
import RugbyLeagueCares from '../images/partners/RLC.png';
import SamhLogo from '../images/partners/samh.png';
import GaaLogo from '../images/partners/gaagpa.png';

export const partnerList = [ 
    {
        partnerType: "Delivery Partner",
        logo: RugbyLeagueCares,
        url: "https://www.rugbyleaguecares.org/",
        name: "Rugby League Cares",
    },
    {
        partnerType: "Delivery Partner",
        logo: SamhLogo,
        url: "https://www.samh.org.uk/",
        name: "Scottish Action for Mental Health",
    },
    {
        partnerType: "Delivery Partner",
        logo: GaaLogo,
        url: "https://www.gaa.ie/",
        name: "Gaelic Athletic Association and Gaelic Players Association",
    },
    {
        partnerType: "     ",
        logo: MovemberLogo,
        url: "https://www.movember.com",
        name: "Movember"
    },
    {
        partnerType: "Research Partner",
        logo: UniversityOfWoolongongLogo,
        url: "https://www.uow.edu.au/",
        name: "University of Wollongong Australia",
    },

    {
        partnerType: "Partner",
        logo: RugbyLeagueWorldCup,
        url: "https://www.rlwc2021.com/",
        name: "Rugby League World Cup England 2021"
    }
];

