export const GetHelpContent = {
    heading: 'GET HELP',
    subHeading: `Everyone goes through tough times. The key is knowing how and when to get help.

Whether you're an athlete, parent or coach, taking care of yourself and looking out for others is most important. 
    `
};

export const UKHelpContactInfo = [
    {
        heading: 'CHILDLINE',
        links: [
            'www.childline.org.uk',
        ],
        phone: [
            '0800 1111'
        ]
    },
    {
        heading: 'PAPYRUS',
        links: [
            'www.papyrus-uk.org',
        ],
        phone: [
            '0800 068 41 41'
        ]
    },
    {
        heading: 'THE MIX',
        links: [
            'www.themix.org.uk'
        ],
        phone: []
    },
    {
        heading: 'SHOUT',
        links: [
            'giveusashout.org'
        ],
        phone: ['Text: 85258']
    }
];

export const IrelandHelpContactInfo = [
    {
        heading: 'CHILDLINE ROI',
        links: [
            'www.childline.ie',
        ],
        phone: [
            '1800 66 66 66'
        ]
    },
    {
        heading: 'CHILDLINE NI',
        links: [
            'www.childline.org.uk',
        ],
        phone: [
            '0800 1111'
        ]
    },
    {
        heading: 'JIGSAW',
        links: [
            'www.jigsaw.ie',
        ],
        phone: [
            
        ]
    },
    {
        heading: 'TEXT ABOUT IT',
        links: [
            'www.textaboutit.ie',
        ],
        phone: [
            'TEXT: 50808'
        ]
    }
]