import React, { Component } from 'react';
import { StyledContactUsContainer,
         StyledContactInfoContainer,
         StyledBottomContainer,
         StyledForm,
         StyledLabel,
         StyledInput,
         StyledTextareaContainer,
         StyledTextarea,
         StyledButtonContents,
         StyledFieldsContainer
        } from './ContactUsForm.styled';
import { Heading, Button, Text } from '@movember/mo-gel';
import { ReactComponent as RightArrow } from '../../../images/icons/right_icon.svg';
import { withTheme } from 'styled-components';

const CONTACT_US_FORM_API = "https://bhou6g6qig.execute-api.ap-southeast-2.amazonaws.com/Development/contact-us-submission";

class ContactUsForm extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstname: {
                isRequired: true,
                error: false,
                value: ''
            },
            lastname: {
                isRequired: true,
                error: false,
                value: ''
            },
            email: {
                isRequired: true,
                error: false,
                value: ''
            },
            phone: {
                value: ''
            },
            comments: {
                isRequired: true,
                error: false,
                value: ''
            },
            error: false,
            loading: false
        };
    }

    async handleSubmit(event: any) {
        event.preventDefault();

        const keys: any = this.state;
        const { firstname, lastname, email, phone, comments } = this.state;

        Object.keys(keys).forEach((key: any) => {
            if (keys[key].isRequired && keys[key].value === '') {
                keys[key].error = true;
                keys.error = true;
            }
        });

        this.setState({
            ...keys
        });

        if (!firstname.error && !lastname.error && !email.error && !comments.error) {
            const { onNavigateBack } = this.props;

            this.setState({
                ...this.state,
                loading: true
            });

            const params = {
                firstname: firstname.value,
                lastname: lastname.value,
                email: email.value,
                phone: phone.value,
                comments: comments.value,
                locale: "UK"
            };

            fetch(CONTACT_US_FORM_API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(params)
            }).then((res: Response) => {
                if (res.status !== 200) {
                    console.error('Contact us failed');
                    onNavigateBack(false);
                } else {
                    console.log('Contact us succeed');
                    onNavigateBack(true);
                }
            }).catch((error: any) => {
                console.error('Contact us failed, ', error);
                onNavigateBack(true);
            }).finally(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        }
    }

    handleChange(event: any) {

        this.setState({
            ...this.state,
            [event.target.name]: {
                ...this.state[event.target.name],
                value: event.target.value
            }
        }
        );
    }

    handleOnBlur(event: any) {
        let error = false;

        if (event.target.value === null || event.target.value === '' || event.target.value === 'undefined') {
            error = true;
        } else {
            error = false;
        }

        this.setState({
            ...this.state,
            [event.target.name]: {
                ...this.state[event.target.name],
                error: error
            },
            error: error
        });
    }

    renderContent() {
        const { firstname, lastname, email, phone, loading, comments } = this.state;

        return <StyledContactUsContainer>
                <StyledForm onSubmit={(event: any) => this.handleSubmit(event)}>
                    <StyledContactInfoContainer>
                        <Heading level="h6" color="black">Your Details:</Heading>
                        <StyledFieldsContainer>
                            <StyledLabel htmlFor="firstname" error={firstname.error}>First name*</StyledLabel>
                            <StyledInput name="firstname" value={firstname.value} onChange={(event: any) => this.handleChange(event)} onBlur={(event: any) => this.handleOnBlur(event)} error={firstname.error} />
                        </StyledFieldsContainer>
                        <StyledFieldsContainer>
                            <StyledLabel htmlFor="lastname" error={lastname.error}>Last name*</StyledLabel>
                            <StyledInput name="lastname" value={lastname.value} onChange={(event: any) => this.handleChange(event)} onBlur={(event: any) => this.handleOnBlur(event)} error={lastname.error} />
                        </StyledFieldsContainer>
                        <StyledFieldsContainer>
                            <StyledLabel htmlFor="email" error={email.error}>Email address*</StyledLabel>
                            <StyledInput name="email" value={email.value} onChange={(event: any) => this.handleChange(event)} onBlur={(event: any) => this.handleOnBlur(event)} error={email.error} />
                        </StyledFieldsContainer>
                        <StyledFieldsContainer>
                            <StyledLabel htmlFor="phone">Phone number</StyledLabel>
                            <StyledInput name="phone" value={phone.value} onChange={(event: any) => this.handleChange(event)} />
                        </StyledFieldsContainer>
                        <StyledFieldsContainer align="flex-end">
                            <Text level="xSmall" color={this.state.error ? this.props.theme.colors.error : 'black'}>*Required field</Text>
                        </StyledFieldsContainer>
                    </StyledContactInfoContainer>
                    <StyledBottomContainer>
                        <StyledLabel style={{width:"100%"}} htmlFor="comments" error={comments.error}>Message*</StyledLabel>
                        <StyledTextareaContainer error={comments.error}>
                            <StyledTextarea placeholder="Write your message or area of interest here" name="comments" onChange={(event: any) => this.handleChange(event)} onBlur={(event: any) => this.handleOnBlur(event)}/>
                        </StyledTextareaContainer>
                        <Button 
                            primary={true}
                            loading={loading}
                            >
                            <StyledButtonContents>
                            Send<RightArrow/>
                            </StyledButtonContents>
                        </Button>
                    </StyledBottomContainer>
                </StyledForm>
            </StyledContactUsContainer>;
    }

    render() {
        const { isSubmitted } = this.props;

        return (
            <>
                {
                    !isSubmitted && this.renderContent()
                }
            </>
        );
    }
}

const ContactUs = withTheme(ContactUsForm);

export { ContactUs as ContactUsForm };